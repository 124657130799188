const generateCategoryWhatsappEnquiryMessage = (categoryId, categoryName) => {
    let message = `Hey, ${process.env.REACT_APP_NAME}!⁣\nI want to know more about the category listed on your website.⁣\nCategory Name : *${categoryName}*\nUrl : ${getShareUrl(categoryId)}`;
    return encodeURIComponent(message);
};
const generateCategoryWhatsappShareMessage = (categoryId) => {
    let message = `Hey, There!⁣\nI have found a beautiful laminate category on ${process.env.REACT_APP_NAME}.⁣\nVisit : ${getShareUrl(categoryId)}`;
    return encodeURIComponent(message);
};

const getShareUrl = (categoryId) => {
    const hostname = `${window.location.protocol}//${window.location.hostname}`;
    let shareUrl = hostname + `/category/${categoryId}`;
    return shareUrl;
};

const generateProductWhatsappEnquiryMessage = (productId, productName) => {
    let message = `Hey, ${process.env.REACT_APP_NAME}!⁣\nI want to know more about the product listed on your website.⁣\nProduct Name : *${productName}*\nUrl : ${getProductShareUrl(productId)}`;
    return encodeURIComponent(message);
};
const generateProductWhatsappShareMessage = (productId) => {
    let message = `Hey, There!⁣\nI have found a beautiful product on ${process.env.REACT_APP_NAME}.⁣\nVisit : ${getProductShareUrl(productId)}`;
    return encodeURIComponent(message);
};
const getProductShareUrl = (productId) => {
    const hostname = `${window.location.protocol}//${window.location.hostname}`;
    let shareUrl = hostname + `/product/${productId}`;
    return shareUrl;
};

module.exports = {
    generateCategoryWhatsappShareMessage,
    generateCategoryWhatsappEnquiryMessage,
    generateProductWhatsappShareMessage,
    generateProductWhatsappEnquiryMessage
}