import colorPalette from "../../constants/colors";
export default function Header({label ={}}){
    return <>
        <div className="flex my-4 justify-center items-center">
                    <div
                        className="w-full sm:w-3/4 md:w-2/3 lg:w-2/3 h-10 sm:h-12 md:h-14 lg:h-18 xl:h-20 text-base sm:text-lg md:text-xl lg:text-2xl h-10 sm:h-12 md:h-14 lg:h-18 xl:h-20 p-3 text-white rounded-full flex items-center justify-center"
                        style={{ ...colorPalette.headerColor }}
                    >
                        <h2 className=" font-semibold rounded-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-5xl">
                           {label}
                        </h2>
                    </div>
                </div>
    </>
}