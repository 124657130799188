import React, { useState } from 'react';
import colorPalette from '../../constants/colors';

function ExploreButton({ text }) {
    const [isHovered, setIsHovered] = useState(false);
    const handleButtonHover = () => {
        setIsHovered(!isHovered);
    }
    return (
        <button onMouseEnter={handleButtonHover} onMouseLeave={handleButtonHover} className="font-semibold md:text-base py-1 px-4 rounded-full shadow-lg transform hover:scale-105 transition duration-300 ease-in-out" style={isHovered ? colorPalette.buttonHover : colorPalette.button}>
            {text}
        </button>
    );
}

export default ExploreButton