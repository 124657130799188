import React, { useState, useEffect } from 'react';
import backgroundImage from "../../assets/images/pexels-scottwebb-2117937.webp";
import APIHandler from '../../helpers/axiosApiHelper';
import { useDispatch } from "react-redux";
import { showOrHideLoader } from '../../Redux/reducers/loaderSlicer';
import CategoryCard from './CategoryCard';

const CategoriesPage = () => {
    const dispatch = useDispatch();
    const [categoryItem, setCategoryItem] = useState([]);
    const getCategoriesList = async () => {
        dispatch(showOrHideLoader());
        let apiData = await APIHandler("categories", "list?status=active", "GET", null, null);
        dispatch(showOrHideLoader());
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                setCategoryItem(apiData.data)
            }
        }
    }
    useEffect(() => {
        getCategoriesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className=''>
                <div className='relative flex flex-col items-center justify-center bg-cover bg-center mt-16 aspect-[16/10] md:aspect-[16/6]' style={{
                    backgroundImage: `url(${backgroundImage})`, width: '100', objectFit: 'cover' 
                }}>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="relative z-10 text-center text-white px-4">
                        <h2 className="text-4xl font-bold mb-4">Categories</h2>
                    </div>
                    {/* <h6 className='font-semibold text-white text-5xl'>Categories</h6> */}
                </div>
                <div className='p-12 md:p-24'>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-12">
                        {categoryItem.map((item) => {
                           return (<CategoryCard item={item} exploreButtonText = {"Explore Products"}/>)
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoriesPage