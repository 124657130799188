import React from "react";
import { useState } from "react";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { showOrHideTalkToUsForm } from '../../Redux/reducers/talkToUsFormSlicer'
function ContactUsForm() {
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [dataValid, setDataValid] = useState(false);

    const dispatch = useDispatch();
    const generateMessage = () => {
        let message = `Hey, ${process.env.REACT_APP_NAME}!⁣\nMy Name is ${name} and I am an architect by profession trying to reach you from ${city}.⁣\nLet's have a discussion about the range of products`;
        return encodeURIComponent(message);
    }

    const handleSendMessage = () => {
        window.open(`https://api.whatsapp.com/send?phone=918982413107&text=${generateMessage()}`, "_blank");
        dispatch(showOrHideTalkToUsForm())
    }

    const handleName = (event) => {
        setName(event.target.value);
    };
    const handleCity = (event) => {
        setCity(event.target.value);
    };
    useEffect(() => {
        if (name.length > 0 && city.length > 0) {
            setDataValid(true);
        }
        else {
            setDataValid(false);
        }
    }, [name, city])
    return (
        <div className="h-dvh">
            <div className=" h-full flex justify-center items-center" >
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full transform transition-all duration-300 ease-in-out">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800">
                            Please tell us your
                        </h2>
                        <button
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() =>
                                dispatch(
                                    showOrHideTalkToUsForm(),
                                )
                            }
                        >
                            &times;
                        </button>
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="Your Name"
                            required
                            onInput={(event) => handleName(event)}
                        />
                    </div>
                    <div className="mb-6">
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="Your City"
                            required
                            onInput={(event) => handleCity(event)}
                        />
                    </div>
                    <p className="text-[red] font-semibold" style={{ display: dataValid ? 'none' : 'block' }}>All fields are mandatory to fill</p>
                    <button
                        disabled={!dataValid}
                        onClick={handleSendMessage}
                        type="submitt"
                        className={`w-full ${dataValid ? 'bg-black' : 'bg-[gray]'} text-white py-2 px-4 rounded-md font-semibold hover:bg-gray-900 transition duration-300`}
                    >
                        Send a Message
                    </button>
                    {/* </a> */}
                </div>

            </div>
        </div>
    );
}

export default ContactUsForm;
