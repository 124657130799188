import ContactUsForm from './ContactUsForm';
// import { showOrHideTalkToUsForm } from '../../Redux/reducers/talkToUsFormSlicer';

function TalkToUsForm() {
  return (
    <>
      <ContactUsForm/>
    </>
  )
}

export default TalkToUsForm
