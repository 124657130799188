// import Carousel from "react-multi-carousel";

const colorPalette = {
    body: {
        backgroundColor: "#fff0db"
    },
    navbar: {
        "backgroundColor": "#231610",
        "color": "#fff0db",
        "font": "font-semibold",
    },
    headerColor: {
        "backgroundColor": "#8D725C",
        "color": "#ffffff"
    },
    footer: {
        "backgroundColor": "#231610",
        "color": "#fff0db",
    },
    footerQuickLinks: {
        "color": "#d9b99b",
    },
    button: {
        backgroundColor: '#8D725C',
        color: "#fff0db"
    },
    buttonHover: {
        backgroundColor: '#fff0db',
        color: "#8D725C"
    },
    productCard: {
        backgroundColor: "#ffdead"
    },
    productDetail: {
        backgroundColor: "#ffdead"
    },
    carouselForm: {
        backgroundColor: "#ffdead",
        color: "8D725C"
    }

}
export default colorPalette;