import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import APIHandler from "../../../helpers/axiosApiHelper";
import UpdateProductForm from "./UpdateProductForm";
import { useDispatch } from "react-redux";
import { triggerSnackbar } from "../../../Redux/reducers/snackbarSlicer";
import { showOrHideLoader } from "../../../Redux/reducers/loaderSlicer";
import AddItem from "./AddItem";
import AddButton from "../AddButton";
import Header from "../CommonHeader";
import SearchUserEnd from "../../SearchUserEnd";
import { motion } from 'framer-motion'
import ImageSlider from "../../ImageSlider";
import colorPalette from "../../../constants/colors";
import CircleButton from "../../Buttons/CircleButton";
import Share from "../../../assets/icons/share.png"
import EditIcon from "../../../assets/icons/edit-text.png";
import DeleteIcon from "../../../assets/icons/delete.png"
import { generateProductWhatsappShareMessage } from "../../commonFunctions";
import TruncatedText from "../../TruncatedText";

export default function Products() {
    const pageCount = 5;
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const [updateItem, setUpdateItem] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [productItem, setProductItem] = useState([]);
    const handlePageChange = (pageNumber) => {
        if (pageNumber === 0 || pageNumber > pageCount) {
            return;
        }
        setCurrentPage(pageNumber);
    };
    const Pagination = () => {
        let pagesBadge = [];
        for (let index = 0; index < pageCount; index++) {
            pagesBadge.push(
                <li
                    key={`${index + 1}`}
                    className="cursor-pointer"
                    onClick={() => {
                        handlePageChange(index + 1);
                    }}
                >
                    <span className="mx-1 inline-flex items-center justify-center w-8 h-8 text-lg font-semibold text-white bg-[#4845d2] rounded-full">
                        {index + 1}
                    </span>
                </li>
            );
        }
        return pagesBadge;
    };
    const [pageSize, setPageSize] = React.useState(10);

    const handleChange = (event) => {
        setPageSize(parseInt(event.target.value));
    };
    const capitalizeWord = (word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalized;
    };
    const productItemComponent = (list) => {
        return list.map((item) => {
            return (
                <motion.div
                    key={item.key}
                    className="group rounded-3xl relative overflow-hidden text-primary"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3 }}
                    style={{
                        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    }}
                >
                    <div className="rounded-lg h-full shadow-lg overflow-hidden" style={{ ...colorPalette.productCard }} key={item.key}>
                        <div className='bg-white'>
                            <ImageSlider images={item.media} />
                        </div>
                        <div className="p-6">
                            <h5 className="text-2xl md:text-sm lg:text-xl xl:text-2xl font-semibold mb-2" style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: 'ellipsis',
                                textAlign: 'center',
                            }}>{item.name}</h5>
                            <div className="text-center mb-2">
                                {item.status === "active" ? (
                                    <div className="bg-green-100 text-green-800 text-sm font-mediums px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                        {capitalizeWord(item.status)}
                                    </div>
                                ) : (
                                    <div className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                        {capitalizeWord(item.status)}
                                    </div>
                                )}
                            </div>

                            <div className="mb-6 flex-grow">
                                <TruncatedText
                                    text={item.description}
                                    limit={100}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 flex flex-col justify-center items-center transition-all duration-500 ease-in-out"
                    >
                        <div className="flex space-x-4 mb-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <a
                                href={`https://api.whatsapp.com/send?text=${generateProductWhatsappShareMessage(
                                    item.key
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <CircleButton icon={Share} />
                            </a>
                            <CircleButton icon={EditIcon} onClick={() => {
                                setUpdateItem(item);
                                setShowUpdateForm(true);
                            }} />
                            <CircleButton icon={DeleteIcon} onClick={() => deleteProduct(item.key)} />
                        </div>
                    </div>
                </motion.div>
            );
        });
    };

    const getProductList = async () => {
        dispatch(showOrHideLoader());
        let apiData = await APIHandler("products", "list", "GET", null, null);
        dispatch(showOrHideLoader());
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                setProductItem(apiData.data);
            }
        }
    };

    const deleteProduct = async (productKey) => {
        dispatch(showOrHideLoader());
        let body = {
            key: productKey,
        };
        let apiData = await APIHandler(
            "products",
            "remove",
            "DELETE",
            body,
            null
        );
        dispatch(showOrHideLoader());
        if (apiData != null) {
            dispatch(
                triggerSnackbar({
                    show: true,
                    message: apiData.message,
                    type: apiData.status,
                })
            );
            if (apiData.statusCode === 200) {
                getProductList();
            }
        }
    };
    useEffect(() => {
        getProductList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showForm, showUpdateForm]);

    return (
        <>
            <div className="w-full rounded-md mx-1 my-1 px-2" key={'admin_products'}>
                <Header label="Products" />
                <div className="bg-[#f2f7fb] rounded-md p-5">
                    <div className="flex justify-between items-center flex-wrap">
                        <div className='w-96'>
                            <SearchUserEnd showStatus={true} source={'product'} />
                        </div>
                        <AddButton
                            label="Add Item"
                            onClick={() => setShowForm(true)}
                        />
                    </div>
                    {productItem.length ? (
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-10">
                            {productItemComponent(productItem)}
                        </div>
                    ) : (
                        <div
                            className="flex w-full h-20 bg-[#f6ecd0] rounded-md justify-center items-center my-10"
                            style={{
                                boxShadow: "10px 10px 20px #babecc",
                            }}
                        >
                            <h3 className="font-bold text-lg">
                                Nothing to show
                            </h3>
                        </div>
                    )}

                    <div className="flex mx-4 py-5 items-center justify-between flex-wrap">
                        <div>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Page Size
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={pageSize}
                                        label="Page Size"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className="flex justify-center mt-5 mb-5">
                            <nav aria-label="Page navigation example">
                                <ul className="flex items-center -space-x-px h-10 text-base">
                                    <li
                                        key={"prev"}
                                        className="rounded-full bg-[#4845d2] p-2 cursor-pointer"
                                        onClick={() => {
                                            handlePageChange(currentPage - 1);
                                        }}
                                    >
                                        <svg
                                            className="w-3 h-3 rtl:rotate-180"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 6 10"
                                        >
                                            <path
                                                stroke="white"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 1 1 5l4 4"
                                            />
                                        </svg>
                                    </li>
                                    {Pagination()}
                                    <li
                                        key={"next"}
                                        className="mx-2 rounded-full bg-[#4845d2] p-2 cursor-pointer"
                                        onClick={() => {
                                            handlePageChange(currentPage + 1);
                                        }}
                                    >
                                        <svg
                                            className="w-3 h-3 rtl:rotate-180"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 6 10"
                                        >
                                            <path
                                                stroke="white"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 9 4-4-4-4"
                                            />
                                        </svg>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    {showForm ? (
                        <AddItem
                            showFormHandler={setShowForm}
                            refreshProductList={getProductList}
                        />
                    ) : null}
                </div>
            </div>
            {showUpdateForm ?
                <UpdateProductForm
                    initialData={updateItem}
                    showFormHandler={setShowUpdateForm}
                />
                : null}
        </>
    );
}
