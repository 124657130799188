import React from 'react'
import { Carousel } from 'react-responsive-carousel';

const ImageSlider = ({ images }) => {
    return (
        <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            showThumbs={false}
            stopOnHover={true}
            swipeable={true}
        >
            {images.map((item) => {
                return (
                    <div>
                        <img
                            className="w-full object-cover aspect-[16/12]"
                            src={item.url}
                            alt={item.name}
                        />
                    </div>
                );
            })}
        </Carousel>
    )
}
export default ImageSlider