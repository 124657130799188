import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from "./reducers/loaderSlicer";
import snackbarReducer from "./reducers/snackbarSlicer";
import sidebarActiveReducer from './reducers/sidebarActiveSlicer';
import talkToUsFormSlicer from './reducers/talkToUsFormSlicer';

export const store = configureStore({
    reducer: {
        loader: loaderReducer,
        snackbar: snackbarReducer,
        sidebarActive: sidebarActiveReducer,
        talkToUsForm: talkToUsFormSlicer
    },
})