
import { useDispatch, useSelector } from 'react-redux';
import Architectimage from '../assets/images/architect.webp'; // Import the background image
import TalkToUsForm from './TalkToUsForm';
import { showOrHideTalkToUsForm } from '../Redux/reducers/talkToUsFormSlicer';
import colorPalette from '../constants/colors';
import { useState } from 'react';

const GetInTouch = () => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const handleButtonHover = () => {
    setIsHovered(!isHovered);
  }

  return (
    /* From Uiverse.io by rajanarahul93 */
    <button
      className="font-bold md:text-lg py-3 px-6 rounded-full shadow-lg transform hover:scale-105 transition duration-300 ease-in-out" style={isHovered ? colorPalette.buttonHover : colorPalette.button} onMouseEnter={handleButtonHover} onMouseLeave={handleButtonHover}
      onClick={() => {
        dispatch(showOrHideTalkToUsForm());
      }}
    >
      Talk to Us
    </button>

  )
}
const Architect = () => {
  const talkToUsForm = useSelector((state) => state.talkToUsForm.value)
  return (
    <>
      <div
        className="bg-cover bg-center aspect-[16/12] md:aspect-[16/6]"
        style={{ backgroundImage: `url(${Architectimage})`, width: "100%", objectFit: 'cover' }}
      >
        <div className="w-full h-full flex flex-col items-center justify-center text-center" style={{ backgroundColor: "rgba(236, 240, 241,0.6)" }}>
          <h1 className="text-3xl md:text-5xl font-bold mb-4 text-primary">Are you an Architect?</h1>
          <GetInTouch />
        </div>
      </div>
      {talkToUsForm ? <TalkToUsForm /> : null}
    </>
  );
};

export default Architect;