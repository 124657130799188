import React, { useEffect, useState } from 'react';
import APIHandler from "../../../helpers/axiosApiHelper";
import { useDispatch } from 'react-redux';
import { showOrHideLoader } from '../../../Redux/reducers/loaderSlicer';
import { triggerSnackbar } from '../../../Redux/reducers/snackbarSlicer';
import colorPalette from '../../../constants/colors';

export default function AddCategoryForm(props) {
    const dispatch = useDispatch();
    const [categoryName, setCategoryName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');
    const [disableButton, setdisableButton] = useState(true);
    const [categoryNameList, setCategoryNameList] = useState([]);
    const [duplicateName, setDuplicateName] = useState(false);
    

    useEffect(() => {
        if (categoryName.length && (duplicateName === false) && (file !== undefined && file.name !== "")) {
            setdisableButton(false);
        }
        else {
            setdisableButton(true)
        }
    }, [categoryName, file, duplicateName]);

    const handleAddCategorySubmit = async () => {
        dispatch(showOrHideLoader())
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        const body = {
            name: categoryName,
            description: description,
            photo: file
        }

        let apiData = await APIHandler("categories", "addCategory", "POST", body, headers);
        dispatch(showOrHideLoader())
        if (apiData != null) {
            dispatch(
                triggerSnackbar({
                    show: true,
                    message: apiData.message,
                    type: apiData.status,
                })
            );
     
            if (apiData.statusCode === 200) {
                props.showFormHandler(false);
            }
        }
    }

    const getCategoriesNameList = async () => {
        dispatch(showOrHideLoader())
        let apiData = await APIHandler("categories", "getAllCategoryNames", "GET", null, null);
        dispatch(showOrHideLoader())
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                let list = apiData.data;
                setCategoryNameList(list);
            }
        }
    }

    useEffect(() => {
        getCategoriesNameList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleName = (event) => {
        let name = event.target.value;
        setCategoryName(name);
        let nameExist = categoryNameList.filter(item => item === name);
        if (nameExist.length) {
            setDuplicateName(true);
        }
        else {
            setDuplicateName(false);
        }
    }
    const handleDescription = (event) => {
        let description = event.target.value;
        setDescription(description);
    }
    const handleFile = (event) => {
        let file = event.target.files[0];
        setFile(file);
    }

    return (
        <>
            <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="z-60 bg-[#000000] bg-opacity-80 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-screen">
                <div className="relative p-4 w-full max-w-md max-h-full left-1/2 top-1/2" style={{
                    transform: "translate(-50%, -50%)"
                }}>
                    <div className="relative bg-[#051641] rounded-lg shadow" style={{ ...colorPalette.carouselForm }}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-[#8e493b]">
                                Add New Category
                            </h3>
                            <button onClick={() => { props.showFormHandler(false) }} type="button" className="text-gray-400 bg-transparent  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-toggle="crud-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form className="p-4 md:p-5" onSubmit={(event) => {
                            event.preventDefault();
                        }}>
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2">
                                    <label htmlFor="name" name='name' className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]">Name</label>
                                    <input onInput={handleName} type="text" id="name" className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type category name" required />
                                    <p className='text-[red] font-semibold' style={{ display: duplicateName ? "block" : 'none' }}>Category with this name already exist</p>
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]">Category Description</label>
                                    <textarea onInput={handleDescription} id="description" rows="4" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Write category description here"></textarea>
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full">
                                <label htmlFor="dropzone-file" className="mt-2 mb-4 bg-white flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white-500">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6 ">
                                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or Webp</p>
                                    </div>
                                    <input onInput={handleFile} on accept="image/*" id="dropzone-file" type="file" name='file' required />
                                </label>
                            </div>

                            <button style={{
                                cursor: disableButton ? "none" : "pointer"
                            }} disabled={disableButton} onClick={handleAddCategorySubmit} type="submit" className={`text-white inline-flex items-center bg-[#8e493b] rounded-lg text-sm px-5 py-2.5 text-center`}>
                                <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                Add
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
