import React from 'react';
import Slider from 'react-slick'; // Import the Slider component from React Slick
import lamp from '../assets/images/lamp.webp';
import floor from '../assets/images/floor.webp';
import welcome from '../assets/images/pexels-scottwebb-2117937.webp';
import boards from '../assets/images/boards.webp';
import img from '../assets/images/90335.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = () => {
  // Slick slider settings
  const settings = {
    dots: true, // Enable navigation dots
    infinite: true, // Loop the slides
    speed: 1500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Automatically transition slides
    autoplaySpeed: 3000, // 3-second interval
    arrows: true, // Enable navigation arrows
    pauseOnHover: false, // Pause autoplay on hover
  };

  return (
    <div className="relative" key={'carousel'}>
      <Slider {...settings} className="h-screen overflow-hidden">
        {/* Slide 1 */}
        <div className="relative h-screen">
          <img
            src={welcome}
            alt="Nature Laminates Slide 1"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center px-4 lg:px-0">
              Welcome to {process.env.REACT_APP_NAME}
            </h1>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="relative h-screen">
          <img
            src={lamp}
            alt="Nature Laminates Slide 2"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center px-4 lg:px-0">
              Explore Our Unique Designs
            </h1>
          </div>
        </div>

        {/* Slide 3 */}
        <div className="relative h-screen">
          <img
            src={floor}
            alt="Nature Laminates Slide 3"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center px-4 lg:px-0">
              Quality You Can Trust
            </h1>
          </div>
        </div>

        {/* Slide 4 */}
        <div className="relative h-screen">
          <img
            src={boards}
            alt="Nature Laminates Slide 4"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center px-4 lg:px-0">
              Continuous Improvement is Our Commitment
            </h1>
          </div>
        </div>

        {/* Slide 5 */}
        <div className="relative h-screen">
          <img
            src={img}
            alt="Nature Laminates Slide 5"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center px-4 lg:px-0">
              Quality is Our Top Priority
            </h1>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;

