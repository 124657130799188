import React, { useState, useEffect } from 'react';
import APIHandler from '../helpers/axiosApiHelper';
import AsyncSelect from 'react-select/async';

function SearchUserEnd({ showStatus, source, status }) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const getSearchOptions = async (query = null, callback) => {
        let queryCount = 0
        setInputValue(query)
        setLoading(true)
        let search = `?`;
        if (query.length) {
            search += `query=${query}`
            queryCount += 1
        }
        if (source) {
            search += queryCount > 0 ? "&" : ""
            search += `source=${source}`
            queryCount += 1
        }
        if (status) {
            search += queryCount > 0 ? "&" : ""
            search += `status=${status}`
            queryCount += 1
        }
        let apiData = await APIHandler("search", `search${search}`, "GET", null, null);
        setLoading(false)
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                let list = apiData.data;
                setOptions(list);
                if (callback != null) {
                    callback(list)
                }
            }
        }
    }

    const dropdownStyles = {
        control: styles => ({ ...styles, backgroundColor: "#ffffff", color: "black", borderRadius: "8px" }),
        singleValue: styles => ({ ...styles, color: "black" }),
        input: styles => ({ ...styles, "input[type='text']:focus": { boxShadow: 'none' }, }),
    }

    const categoriesDropdownFormatOptionLabel = ({ key, name, source, status }) => {
        return (
            <div key={key} className='flex justify-between border border-gray-200 rounded-md px-8 py-4' style={{
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            }}>
                <div style={{
                    width: "50%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: 'ellipsis'
                }}>{name}</div>
                <div className='flex items-center'>
                    <span className="capitalize	bg-[#ffdead] me-2 px-2.5 py-0.5 rounded font-bold">{source}</span>
                    {showStatus ? <div className={`w-4 h-4 rounded-full`} style={{
                        backgroundColor: status !== "active" ? "red" : "green"
                    }}></div> : null}
                </div>
            </div>
        )
    };

    const handleInputChange = (input) => {
        setInputValue(input);
        if (!input.length) {
            getSearchOptions('');

        }
    };

    useEffect(() => {
        getSearchOptions(inputValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event) => {
        window.location.href = `/${event.source}/${event.key}`

    }

    return (
        /* From Uiverse.io by seyed-mohsen-mousavi */
        <div className="">
            <AsyncSelect
                key={'search'}
                closeMenuOnSelect={true}
                isLoading={loading}
                autoFocus={false}
                styles={dropdownStyles}
                isSearchable={true}
                name="categoriesDropdown"
                defaultOptions={options}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                formatOptionLabel={categoriesDropdownFormatOptionLabel}
                placeholder={'Type to search'}
                loadOptions={getSearchOptions}
                onChange={handleChange}
            />
        </div>
    )
}

export default SearchUserEnd