import '../styles/LandingPage.css';
import HomepageCategories from './Categories/HomepageCategories';
// import ContactForm from './ContactForm';
import Carousel from './Carousel';
import AboutUs from './AboutUs';
import Testimonials from './Testimonials';
import FeaturesSection from './FeaturesSection'
// import FeaturedProducts from './FeaturedProducts'
import SearchUserEnd from './SearchUserEnd';
// import GetInTouchButton from './GetInTouchButton';
import Architect from './Architect';

function Home() {
    return (
        <div className="LandingPage" >
            <Carousel />
            <div className='px-8 md:px-16'>
                <FeaturesSection />
                <SearchUserEnd status={'active'} />
                <HomepageCategories />
                {/* <FeaturedProducts /> */}
                <Testimonials />
                <AboutUs />
                {/* <ContactForm /> */}
            </div>
            <Architect />
        </div>
    );
}

export default Home;
