import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
// import 'animate.css/animate.min.css';

const testimonials = [
  {
    name: `${process.env.REACT_APP_NAME}`,

    text: 'This product has completely transformed our business. The ease of use and excellent support make it stand out.',
  },
  {
    name: `${process.env.REACT_APP_NAME}`,

    text: 'This product has completely transformed our business. The ease of use and excellent support make it stand out.',
  },
  {
    name: `${process.env.REACT_APP_NAME}`,

    text: 'This product has completely transformed our business. The ease of use and excellent support make it stand out.',
  },

];

const Testimonials = () => {
  return (
    <section className="py-12 text-primary">
      <div className="container mx-auto px-0 md:px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          What Our Clients Say
        </h2>
        <div className="flex flex-wrap justify-center">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="mx-0 md:mx-8 w-full md:w-1/3 lg:w-1/4 px-4 mb-8 animate__animated animate__fadeInUp"
              style={{ animationDelay: `${index * 0.2}s` }}
            >

              <div className="bg-orange-200 py-7 px-4" style={{
                // backgroundColor:'#543e38',
                borderRadius: "50% 10% 50% 10%/10% 50% 10% 50%"
              }}>
                <div className="text-center">
                  <FaQuoteLeft className="text-3xl mb-4 " />
                  <p className="mb-4">{testimonial.text}</p>
                  <h3 className="text-xl font-bold">
                    {testimonial.name}
                  </h3>
                  {/* <p className="text-indigo-500">{testimonial.position}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
