import React from 'react'

function CircleButton({ icon, onClick }) {
    return (
        <button type="button" className="w-12 h-12 bg-white rounded-full shadow-lg flex items-center justify-center" onClick={onClick}>
            <img src={icon} alt="Icon" className="w-6 h-6" />
        </button>
    )
}

export default CircleButton