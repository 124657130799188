import React from 'react';
import PageNotFound from "../assets/images/not_found_2.webp"

function NotFound() {
    return (
        <div className='w-full 
         flex justify-center mt-12 items-center px-14 md:px-16 '>
            <img src={PageNotFound} className='aspect-[16/14] md:h-screen' alt='not_found' />
        </div>
    )
}

export default NotFound