import React, { useEffect, useState } from "react";
import APIHandler from "../../../helpers/axiosApiHelper";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../Redux/reducers/loaderSlicer";
import { triggerSnackbar } from "../../../Redux/reducers/snackbarSlicer";
import Select from "react-select";
import colorPalette from "../../../constants/colors";
export default function UpdateCategorieForm({ initialData, showFormHandler }) {
    const [existingData, setExistingData] = useState(initialData);

    const dispatch = useDispatch();
    const [key, setKey] = useState("");
    const [categoryName, setCategoryName] = useState(existingData.name);
    const [description, setDescription] = useState(existingData.description);
    // const [file, setFile] = useState('');
    const [disableButton, setDisableButton] = useState(true);
    const [selectStatus, setSelectStatus] = useState(null);
    const [categoryNameList, setCategoryNameList] = useState([]);
    const [duplicateName, setDuplicateName] = useState(false);

    const checkStatus = [
        {
            value: "active",
            label: "Active",
        },
        {
            value: "inactive",
            label: "Inactive",
        },
    ];
    useEffect(() => {
        // Populate form fields with initial data when available
        if (initialData) {
            setKey(initialData.key);
            setCategoryName(initialData.name || "");
            setDescription(initialData.description || "");
            setSelectStatus(initialData.status);
            // setFile(initialData.photo || ''); // Assuming `photo` is part of the initial data
        }
    }, [initialData]);

    useEffect(() => {
        if (
            categoryName.length && (duplicateName === false)
            // && (file !== undefined && file.name !== "")
        ) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [categoryName, duplicateName]);

    const handleAddCategorySubmit = async () => {
        dispatch(showOrHideLoader());
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        const body = {
            key: key,
            name: categoryName,
            description: description,
            status: selectStatus
            // photo: file
        };

        let apiData = await APIHandler(
            "categories",
            "updateCategory",
            "PUT",
            body,
            headers
        );
        dispatch(showOrHideLoader());
        if (apiData != null) {
            dispatch(
                triggerSnackbar({
                    show: true,
                    message: apiData.message,
                    type: apiData.status,
                })
            );

            if (apiData.statusCode === 200) {
                showFormHandler(false);
            }
        }
    };

    const handleName = (event) => {
        let name = event.target.value;
        setCategoryName(name);
        let nameExist = categoryNameList.filter(item => item === name);
        if (nameExist.length) {
            setDuplicateName(true);
        }
        else {
            setDuplicateName(false);
        }
    }

    const handleDescription = (event) => {
        setDescription(event.target.value);
    };

    // const handleFile = (event) => {
    //     setFile(event.target.files[0]);
    // };
    const dropdownStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            color: "gray",
            borderRadius: "8px",
        }),
        input: (styles) => ({ ...styles, color: "white" }),
        singleValue: (styles) => ({ ...styles, color: "green" }),
    };

    useEffect(() => {
        if (initialData) {
            setExistingData(initialData);
        }
    }, [initialData]);

    const getCategoriesNameList = async () => {
        dispatch(showOrHideLoader())
        let apiData = await APIHandler("categories", "getAllCategoryNames", "GET", null, null);
        dispatch(showOrHideLoader())
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                let list = apiData.data;
                setCategoryNameList(list);
            }
        }
    }

    useEffect(() => {
        getCategoriesNameList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div
                id="crud-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="z-60 bg-[#000000] bg-opacity-80 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-screen"
            >
                <div
                    className="relative p-4 w-full max-w-md max-h-full left-1/2 top-1/2"
                    style={{ transform: "translate(-50%, -50%)" }}
                >
                    <div className="relative bg-[#051641] rounded-lg shadow" style={{ ...colorPalette.carouselForm }}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-[#8e493b]">
                                Update Category
                            </h3>
                            <button
                                onClick={() => {
                                    showFormHandler(false);
                                }}
                                type="button"
                                className="text-gray-400 bg-transparent  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                data-modal-toggle="crud-modal"
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form
                            className="p-4 md:p-5"
                            onSubmit={(event) => {
                                event.preventDefault();
                                handleAddCategorySubmit();
                            }}
                        >
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2">
                                    <label
                                        htmlFor="name"
                                        name="name"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                    >
                                        Name
                                    </label>
                                    <input
                                        value={categoryName}
                                        onInput={handleName}
                                        type="text"
                                        id="name"
                                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Type category name"
                                        required
                                    />
                                    <p className='text-[red] font-semibold' style={{ display: duplicateName ? "block" : 'none' }}>Category with this name already exist</p>

                                </div>
                                <div className="col-span-2">
                                    <label
                                        htmlFor="description"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                    >
                                        Category Description
                                    </label>
                                    <textarea
                                        value={description}
                                        onInput={handleDescription}
                                        id="description"
                                        rows="4"
                                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Write category description here"
                                    ></textarea>
                                </div>
                            </div>


                            <div>
                                <label
                                    htmlFor="name"
                                    name="name"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                >
                                    Status
                                </label>
                                <Select
                                    styles={dropdownStyles}
                                    name="StatusDropdown"
                                    options={checkStatus}
                                    placeholder={"Select Status"}
                                    value={checkStatus.filter(item => item.value === selectStatus)[0]}
                                    onChange={(event) => {
                                        setSelectStatus(event.value);
                                    }}
                                    required
                                />
                            </div>
                            <button
                                style={{
                                    cursor: disableButton
                                        ? "not-allowed"
                                        : "pointer",
                                }}
                                disabled={disableButton}
                                type="submit"
                                className="text-white inline-flex items-center bg-[#8e493b] rounded-lg text-sm px-9  py-2.5 text-center mt-4"
                            >
                                <svg
                                    className="me-1 -ms-1 w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                Update
                            </button>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
