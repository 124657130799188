import { Route, Routes } from "react-router-dom";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import Main from "./components/Admin/Main";
import SignIn from "./components/Admin/SignIn";
import Dashboard from "./components/Admin/Dashboard";
import Home from "./components/Home";
import Navbar from "./components/Navbars/Navbar";
// import Cards from "./components/Card1";
import colorPalette from "./constants/colors";
import CategoriesPage from "./components/Categories/Categories";
import Footer from "./components/Footer";
import CategoryDetails from "./components/Categories/CategoryDetails";
import Loader from "./components/Loader";
import { triggerSnackbar } from "./Redux/reducers/snackbarSlicer";
import ProductDetails from "./components/Products/ProductDetails";
import About from "./components/AboutUs/AboutUsPage";
import ContactForm from "./components/ContactUs/ContactForm";
import { Helmet } from "react-helmet-async";
import NotFound from "./components/NotFound";
import SomethingWentWrong from "./components/SomethingWentWrong";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const loader = useSelector((state) => state.loader.value);
  const snackbar = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(triggerSnackbar({ show: false }));
  };

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} - Premium Quality Laminates for
          Every Space
        </title>
        <meta
          name="description"
          content="Explore top-quality laminates at Nature Laminates. Discover a wide range of designs including woodgrain, solid colors, textured, metallic, and custom laminates."
        />
        <meta
          name="keywords"
          content="premium laminates, woodgrain laminates, eco-friendly laminates, textured laminates, solid color laminates, custom laminates, water resistant laminates, affordable pricing laminates"
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Nature Laminates",
              "url": "https://www.naturelaminates.com",
              "logo": "https://www.naturelaminates.com/logo.png",
              "sameAs": [
                "https://www.facebook.com/naturelaminates",
                "https://www.instagram.com/naturelaminates"
              ]
            }
          `}
        </script>
      </Helmet>
      {loader ? <Loader /> : null}
      <div style={{ backgroundColor: colorPalette.body.backgroundColor }}>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/admin" element={<SignIn />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          {/* <Route path="/cards" element={<Cards />} /> */}
          <Route
            path="/product/:productId"
            element={<ProductDetails />}
          />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route
            path="/category/:categoryId"
            element={<CategoryDetails />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route
            path="/somethingwentwrong"
            element={<SomethingWentWrong />}
          />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <Snackbar
          open={snackbar.show}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbar.type}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default App;
