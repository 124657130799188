import React from 'react'
import { motion } from 'framer-motion';
import { generateCategoryWhatsappEnquiryMessage, generateCategoryWhatsappShareMessage } from "../commonFunctions";
import WhatsApp from '../../assets/icons/whatsapp.png';
import Share from '../../assets/icons/share.png';
import { Link } from 'react-router-dom';
import ExploreButton from '../Buttons/ExploreButton';
import CircleButton from "../Buttons/CircleButton";

function CategoryCard({ item, exploreButtonText }) {
  return (
    <motion.div
      key={item.key}
      className="group rounded-3xl relative overflow-hidden"
      style={{
        aspectRatio: 16 / 16,
        backgroundImage: `url(${item.photo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      whileHover={{ scale: 1.1 }}
      transition={{ duration: 0.3 }}
    >
      <div className='flex justify-center items-end absolute py-4 mb-8 bottom-0 left-0 right-0' style={{
        backdropFilter: "blur(30px)",
        boxShadow: "0px 0px 30px rgba(0,0,0,0.4)",
        backgroundColor: "rgb(0,0,0,0.4)"
      }}>
        <h1 className='font-bold text-white text-xl'>{item.name}</h1>
      </div>
      <div
        className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 flex flex-col justify-center items-center transition-all duration-500 ease-in-out"
      >
        <div className="flex space-x-4 mb-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
          <a
            href={`https://api.whatsapp.com/send?text=${generateCategoryWhatsappShareMessage(
              item.key
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CircleButton icon={Share} />
          </a>
          <a
            href={`https://api.whatsapp.com/send?phone=918982413107&&text=${generateCategoryWhatsappEnquiryMessage(
              item.key, item.name
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CircleButton icon={WhatsApp} />
          </a>
        </div>
        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
          <Link to={`/category/${item.key}`}>
            <ExploreButton text={exploreButtonText} />
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

export default CategoryCard