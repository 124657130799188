import { ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSidebarActiveCell } from "../../Redux/reducers/sidebarActiveSlicer";

const SidebarContext = createContext();

export default function Sidebar({ children }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <aside className="my-2">
            <nav
                className={`py-4 flex flex-col bg-[#111827] border-r shadow-sm transition-all duration-300 rounded-r-lg ${expanded ? "w-60" : "w-16"
                    }`}
            >
                <div className="p-4 pb-2 flex justify-between items-center">
                    {expanded ? (
                        <h3 className="text-white text-2xl">Menu</h3>
                    ) : null}

                    <button
                        onClick={() => setExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                    >
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button>
                </div>

                <SidebarContext.Provider value={{ expanded }}>
                    <ul className="flex-1 px-3">{children}</ul>
                </SidebarContext.Provider>
            </nav>
        </aside>
    );
}

export function SidebarItem({ icon, text, active, alert, selector }) {
    const { expanded } = useContext(SidebarContext);
    const selected = useSelector((state) => state.sidebarActive.selector);
    const dispatch = useDispatch();
    return (
        <li
            onClick={() => dispatch(changeSidebarActiveCell(selector))}
            className={`text-white
        relative flex items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${(selector === selected)
                    ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
                    : "hover:bg-[#4845d2] text-gray-600"
                }
    `}
        >
            {icon}
            <span
                className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"
                    }`}
            >
                {text}
            </span>
            {alert && (
                <div
                    className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"
                        }`}
                />
            )}

            {!expanded && (
                <div
                    className={`
          absolute left-full rounded-md px-2 py-1 ml-6
          bg-indigo-100 text-indigo-800 text-sm
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
      `}
                >
                    {text}
                </div>
            )}
        </li>
    );
}
