import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { generateProductWhatsappEnquiryMessage, generateProductWhatsappShareMessage } from '../commonFunctions';
import CircleButton from '../Buttons/CircleButton';
import ExploreButton from '../Buttons/ExploreButton';
import WhatsApp from '../../assets/icons/whatsapp.png';
import Share from '../../assets/icons/share.png';
import colorPalette from '../../constants/colors';
import ImageSlider from '../ImageSlider';
import TruncatedText from '../TruncatedText';

export default function ProductCard({ item }) {
    // Function to generate stars based on the rating value
    // const generateStars = (rating) => {
    //     let stars = [];
    //     for (let i = 1; i <= 5; i++) {
    //         if (i <= rating) {
    //             stars.push
    //                 (
    //                     <span key={i} className="text-yellow-500">
    //                         &#9733;
    //                     </span>
    //                 );
    //         } else {
    //             stars.push(
    //                 <span key={i} className="text-gray-300">
    //                     &#9733;
    //                 </span>
    //             );
    //         }
    //     }
    //     return stars;
    // };

    return (
        <motion.div
            key={item.key}
            className="group rounded-3xl relative overflow-hidden text-primary"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            style={{
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            }}
        >
            <div className="rounded-lg h-full shadow-lg overflow-hidden" style={{ ...colorPalette.productCard }} key={item.key}>
                <div className='bg-white'>
                    <ImageSlider images={item.media} />
                </div>
                <div className="p-6">
                    <h5 className="text-2xl md:text-sm lg:text-xl xl:text-2xl font-semibold mb-2" style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: 'ellipsis',
                        textAlign: 'center',
                    }}>{item.name}</h5>

                    <div className="mb-6 flex-grow">
                        <TruncatedText
                            text={item.description}
                            limit={100}
                        />
                    </div>
                    <div className="flex items-center mt-2 mb-2">
                        <span className="text-sm font-medium">₹</span>
                        <span className="text-green-600 font-normal text-xl">{item.price}</span>
                    </div>
                </div>
            </div>
            <div
                className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 flex flex-col justify-center items-center transition-all duration-500 ease-in-out"
            >
                <div className="flex space-x-4 mb-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                    <a
                        href={`https://api.whatsapp.com/send?text=${generateProductWhatsappShareMessage(
                            item.key
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CircleButton icon={Share} />
                    </a>
                    <a
                        href={`https://api.whatsapp.com/send?phone=918982413107&&text=${generateProductWhatsappEnquiryMessage(
                            item.key, item.name
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CircleButton icon={WhatsApp} />
                    </a>
                </div>
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                    <Link to={`/product/${item.key}`}>
                        <ExploreButton text={'View Product'} />
                    </Link>
                </div>
            </div>
        </motion.div>
    )
}
