import React, { useState } from 'react';
import APIHandler from "../../../helpers/axiosApiHelper";
import { useDispatch } from 'react-redux';
import { showOrHideLoader } from '../../../Redux/reducers/loaderSlicer';
import colorPalette from '../../../constants/colors';

import { triggerSnackbar } from '../../../Redux/reducers/snackbarSlicer';
export default function CarouselForm(props) {
    const dispatch = useDispatch();
    const [file, setFile] = useState('');
    const [messageColor, setMessageColor] = useState('red');
    const [message, setMessage] = useState('Carousel image dimensions should be greater than or equal to 1920 x 1080');
    const [disableButton, setDisableButton] = useState(true);

    const handleAddCategorySubmit = async () => {
        dispatch(showOrHideLoader())
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        const body = {
            url: file
        }

        let apiData = await APIHandler("carousels", "addCarousels", "POST", body, headers);
        dispatch(showOrHideLoader())
        if (apiData != null) {
            dispatch(
                triggerSnackbar({
                    show: true,
                    message: apiData.message,
                    type: apiData.status,
                })
            );

            if (apiData.statusCode === 200) {
                props.showFormHandler(false);
            }
            else if (apiData.statusCode === 500) {
            }
        }
    }

    const handleFile = (event) => {
        let file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (img.width >= 1920 && img.height >= 1080) {
                        setMessageColor('green');
                        setMessage('Valid image dimensions');
                        setDisableButton(false);
                    }
                    else {
                        setMessageColor('red');
                        setMessage('Carousel image dimensions should be greater than or equal to 1920 x 1080');
                        setFile('');
                        setDisableButton(true)
                    }
                };
                img.onerror = () => {
                    setMessage('Error loading image.');
                };
                img.src = e.target.result;
            };
            reader.onerror = () => {
                setMessage('Error reading file.');
            };
            reader.readAsDataURL(file);
        }
        setFile(file);
    }

    return (
        <>
            <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="z-60 bg-[#000000] bg-opacity-80 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-screen">
                <div className="relative  p-4 w-full max-w-md max-h-full left-1/2 top-1/2" style={{
                    transform: "translate(-50%, -50%)"
                }}>
                    <div className="relative  rounded-lg shadow" style={{ ...colorPalette.carouselForm }}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-[#8e493b] ">
                                Add New Carousel
                            </h3>
                            <button onClick={() => { props.showFormHandler(false) }} type="button" className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="#8e493b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form className="p-4 md:p-5" onSubmit={(event) => {
                            event.preventDefault();
                        }}>
                            <div className="flex items-center justify-center w-full">
                                <label htmlFor="dropzone-file" className="mt-2 mb-2 flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white  ">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or Webp</p>
                                    </div>
                                    <input className='text-center text-wrap  px-8 text-white' onInput={handleFile} on accept="image/*" id="dropzone-file" type="file" name='file' required />

                                </label>
                            </div>
                            {message && <p style={{ color: `${messageColor}`, }}>{message}</p>}

                            <button style={{
                                ...colorPalette.button,
                                cursor: disableButton ? "none" : "pointer"
                            }} disabled={disableButton} onClick={handleAddCategorySubmit} type="submit" className={`mt-2 text-white inline-flex items-center bg-[#0F6083] rounded-lg text-sm px-5 py-2.5 text-center`}>
                                <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                Add
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
