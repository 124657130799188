import axios from 'axios';

export default async function APIHandler(prefix, endpoint, method, body, headerMetadata = null) {
    const baseUrl = (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_LOCAL;

    let url = baseUrl;
    if (prefix !== undefined && prefix !== null) {
        url = url + `/${prefix}`
    }
    if (endpoint !== undefined && endpoint !== null) {
        url = url + `/${endpoint}`
    }
    let metaData = {
        headers: {
            'Content-Type': 'application/json',
            authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        'method': method
    };
    if (headerMetadata != null) {
        metaData.headers = {
            ...metaData.headers,
            ...headerMetadata
        }
    }

    if (body !== undefined && body !== null) {
        metaData['data'] = body
    }
    return await axios(url, metaData)
        .then(res => {
            return res.data
        })
        .catch(error => {
            let { status } = error.toJSON();
            if (status === 404) {
                window.location.href = "/notfound"
            }
            if (status === 403) {
                window.location.href = "/admin"
            }
            else if (status === 500) {
                window.location.href = "/somethingwentwrong"
            }
            else if (status === 400) {
                return error.response?.data
            }
        })
}