import { TriangleAlert } from 'lucide-react';
import React from 'react';
import ExploreButton from './Buttons/ExploreButton';
import { Link } from 'react-router-dom';



function SomethingWentWrong() {
    return (
        <div className='w-full flex justify-center mt-14 items-center px-8 md:px-16'>
            <div className='flex flex-col justify-center items-center mb-4  w-full aspect-[16/8] rounded-lg'>

                <TriangleAlert size={200} color='red' />
                <h1>Oops! Something Went Wrong.</h1>
                <Link to={'/'}>
                    <ExploreButton text={'Go Home'} />
                </Link>
            </div>
        </div>
    )
}

export default SomethingWentWrong