
import React, { useState, useEffect } from 'react';
import APIHandler from '../../helpers/axiosApiHelper';
import CategoryCard from './CategoryCard';
import { useDispatch } from 'react-redux';
import { showOrHideLoader } from '../../Redux/reducers/loaderSlicer';
const HomepageCategories = () => {
  const dispatch = useDispatch();
  const [categoryItem, setCategoryItem] = useState([]);

  const getCategoriesList = async () => {
    dispatch(showOrHideLoader());
    let apiData = await APIHandler("categories", "list?limit=6", "GET", null, null);
    dispatch(showOrHideLoader());
    if (apiData != null) {
      if (apiData.statusCode === 200) {
        setCategoryItem(apiData.data);
      }
    }
  };

  useEffect(() => {
    getCategoriesList();
    // eslint-disable-next-line
  }, []);

  const ShowcaseComponent = () => {
    return (
      <section id="categories" className="py-10">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-8 text-primary">Our Categories</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16">
            {categoryItem.map((item) => {
              return (
                <CategoryCard item={item} exploreButtonText={"Explore Category"} />
              )
            })}
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      {categoryItem.length ? ShowcaseComponent() : null}
    </>
  );
};

export default HomepageCategories;
