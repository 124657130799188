import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { motion } from 'framer-motion';
import APIHandler from "../../../helpers/axiosApiHelper";
import colorPalette from "../../../constants/colors";
import CarouselForm from "./CarouselForm";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../Redux/reducers/loaderSlicer";
import AddButton from "../AddButton";
import Header from "../CommonHeader";
import Trash from "../../../assets/icons/trash-2.svg";
import CircleButton from "../../Buttons/CircleButton";
import { triggerSnackbar } from "../../../Redux/reducers/snackbarSlicer";

export default function Carousel() {
    const pageCount = 5;
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [carouselItem, setCarouselItem] = useState([]);
    const handlePageChange = (pageNumber) => {
        if (pageNumber === 0 || pageNumber > pageCount) {
            return;
        }
        setCurrentPage(pageNumber);
    };
    const Pagination = () => {
        let pagesBadge = [];
        for (let index = 0; index < pageCount; index++) {
            pagesBadge.push(
                <li
                    key={`${index + 1}`}
                    className="cursor-pointer"
                    onClick={() => {
                        handlePageChange(index + 1);
                    }}
                >
                    <span className="mx-1 inline-flex items-center justify-center w-8 h-8 text-lg font-semibold text-white bg-[#4845d2] rounded-full">
                        {index + 1}
                    </span>
                </li>
            );
        }
        return pagesBadge;
    };
    const [pageSize, setPageSize] = React.useState(10);

    const handleChange = (event) => {
        setPageSize(parseInt(event.target.value));
    };

    const carouselItemComponent = (list) => {
        return list.map((item) => {
            return (
                <div className="p-1 bg-[red] rounded-3xl" style={{ backgroundColor: colorPalette.navbar.backgroundColor }}>
                    <motion.div
                        key={item.key}
                        className="group rounded-3xl relative overflow-hidden"
                        style={{
                            aspectRatio: 16 / 9,
                            backgroundImage: `url(${item.url})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div
                            className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 flex flex-col justify-center items-center transition-all duration-500 ease-in-out"
                        >
                            <div className="flex space-x-4 mb-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                                <button onClick={() => deleteCarousel(item.key)}>
                                    <CircleButton icon={Trash} />
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </div>
            );
        });
    };

    const getCarouselList = async () => {
        dispatch(showOrHideLoader())
        let apiData = await APIHandler(
            "carousels",
            "list",
            "GET",
            null,
            null
        );
        dispatch(showOrHideLoader())
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                setCarouselItem(apiData.data);
            }
        }
    };

    const deleteCarousel = async (carouselKey) => {
        dispatch(showOrHideLoader())
        const body = {
            "key": carouselKey
        }
        let apiData = await APIHandler(
            "carousels",
            "remove",
            "DELETE",
            body,
            null
        );
        dispatch(showOrHideLoader())
        if (apiData != null) {
            dispatch(
                triggerSnackbar({
                    show: true,
                    message: apiData.message,
                    type: apiData.status,
                })
            );
     
            if (apiData.statusCode === 200) {
                getCarouselList();
            }
        }
    };
    useEffect(() => {
        getCarouselList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showForm]);

    return (
        <>
            <div className="w-full rounded-md mx-1 my-1 px-2" key={'carousel'}>
                <Header label="Carousels" />
                <div className="bg-[#f2f7fb] rounded-md p-5">
                    <div className="flex justify-end items-center flex-wrap">
                        <AddButton onClick={() => setShowForm(true)} label="Add Carousel" />
                    </div>
                    {carouselItem.length ? (
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 my-10">
                            {carouselItemComponent(carouselItem)}
                        </div>
                    ) : (
                        <div
                            className="flex w-full h-20 bg-[#f6ecd0] rounded-md justify-center items-center my-10"
                            style={{
                                boxShadow: "10px 10px 20px #babecc",
                            }}
                        >
                            <h3 className="font-bold text-lg">
                                Nothing to show
                            </h3>
                        </div>
                    )}
                    <div className="flex mx-4 py-5 items-center justify-between flex-wrap">
                        <div>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Page Size
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={pageSize}
                                        label="Page Size"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className="flex justify-center mt-5 mb-5">
                            <nav aria-label="Page navigation example">
                                <ul className="flex items-center -space-x-px h-10 text-base">
                                    <li
                                        key={"prev"}
                                        className="rounded-full bg-[#4845d2] p-2 cursor-pointer"
                                        onClick={() => {
                                            handlePageChange(currentPage - 1);
                                        }}
                                    >
                                        <svg
                                            className="w-3 h-3 rtl:rotate-180"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 6 10"
                                        >
                                            <path
                                                stroke="white"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 1 1 5l4 4"
                                            />
                                        </svg>
                                    </li>
                                    {Pagination()}
                                    <li
                                        key={"next"}
                                        className="mx-2 rounded-full bg-[#4845d2] p-2 cursor-pointer"
                                        onClick={() => {
                                            handlePageChange(currentPage + 1);
                                        }}
                                    >
                                        <svg
                                            className="w-3 h-3 rtl:rotate-180"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 6 10"
                                        >
                                            <path
                                                stroke="white"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 9 4-4-4-4"
                                            />
                                        </svg>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    {showForm ? (
                        <CarouselForm
                            showFormHandler={setShowForm}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
}
