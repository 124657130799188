import '../styles/Loader.css';

const Loader = () => {
    return (
        <div className="flex fixed justify-center items-center text-center h-screen w-screen bg-[#000000] bg-opacity-80 z-100" style={{
            height: "100%"
        }}>
            <div className='loader'></div>
        </div>
    );
};

export default Loader;