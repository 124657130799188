import React, { useState, useEffect } from "react";
import APIHandler from "../../helpers/axiosApiHelper";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../Redux/reducers/loaderSlicer";
import { generateProductWhatsappEnquiryMessage, generateProductWhatsappShareMessage } from "../commonFunctions";
import ExploreButton from '../Buttons/ExploreButton';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  const getproductDetails = async () => {
    dispatch(showOrHideLoader());
    let apiData = await APIHandler(
      "products",
      `productDetailsBykey/${productId}`,
      "GET",
      null,
      null,
      null
    );
    dispatch(showOrHideLoader());
    if (apiData != null) {
      let { data, statusCode } = apiData;
      if (statusCode === 200) {
        setProductDetails(data);
      }
    }
  };
  useEffect(() => {
    getproductDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {productDetails ? (
        <div className="py-2 px-6 text-primary">
          <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8 mt-20">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Product Images */}

              <Carousel
                showArrows={true}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={true}
                stopOnHover={true}
                swipeable={true}
              >
                {productDetails.media.map((item) => {
                  return (
                    <div>
                      <img
                        className="w-full object-cover aspect-[16/12]"
                        src={item.url}
                        alt={item.name}
                      />
                    </div>
                  );
                })}
              </Carousel>

              {/* Product Details */}
              <div className="bg-[#ffdead] gap-10 rounded-3xl p-8">
                <h2 className="text-3xl font-bold mb-2">
                  {productDetails.name}
                </h2>

                <div className="flex items-center mb-2">
                  <span className="text-sm font-medium">
                    ₹
                  </span>
                  <span className="text-green-600 font-bold text-xl">
                    {productDetails.price}
                  </span>
                </div>

                {/* Product Rating */}
                <div className="mb-4">
                  {/* <div className="flex items-center">
                                    {[...Array(5)].map((_, index) => (
                                        <svg
                                            key={index}
                                            className={`w-6 h-6 ${
                                                index < product.rating
                                                    ? "text-yellow-500"
                                                    : "text-gray-300"
                                            }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.175 3.608a1 1 0 00.95.69h3.862c.969 0 1.371 1.24.588 1.81l-3.125 2.29a1 1 0 00-.364 1.118l1.175 3.607c.3.921-.755 1.688-1.54 1.118l-3.125-2.29a1 1 0 00-1.176 0l-3.125 2.29c-.784.57-1.838-.197-1.539-1.118l1.175-3.607a1 1 0 00-.364-1.118l-3.125-2.29c-.784-.57-.38-1.81.588-1.81h3.862a1 1 0 00.95-.69l1.175-3.608z" />
                                        </svg>
                                    ))}
                                </div> */}
                  <p className="text-xl font-semibold">
                    Dimensions:
                    <span className="font-normal"> {productDetails.dimension_unit}</span>
                  </p>
                  <p className="text-xl font-semibold">
                    Length:
                    <span className="font-normal"> {productDetails.length}</span>
                  </p>
                  <p className="text-xl font-semibold">
                    Width:
                    <span className="font-normal"> {productDetails.width}</span>
                  </p>
                </div>

                <p className="mb-6 text-lg">
                  {productDetails.description}
                </p>

                {/* Get in touch button */}

                <div className="flex justify-around items-center text-center">
                  <a
                    href={`https://api.whatsapp.com/send?text=${generateProductWhatsappShareMessage(
                      productDetails.key
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full sm:w-auto"
                  >
                    {/* <button className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg ">
                                            Get in touch
                                        </button> */}
                    <ExploreButton text={'Share'} />
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?phone=918982413107&&text=${generateProductWhatsappEnquiryMessage(
                      productDetails.key, productDetails.name
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full sm:w-auto"
                  >
                    {/* <button className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg">
                                            Get in touch
                                        </button> */}
                    <ExploreButton text={'Enquiry'} />
                  </a>
                </div>

                {/* Additional Info Accordion */}
                {/* <div>
                                {[
                                    "Characteristics",
                                    "Care",
                                    "Shipping",
                                    "Returns",
                                ].map((tab) => (
                                    <div key={tab}>
                                        <button
                                            onClick={() => toggleTab(tab)}
                                            className="w-full text-left py-2 border-b border-gray-300 text-gray-700 font-semibold"
                                        >
                                            {tab}
                                        </button>
                                        {activeTab === tab && (
                                            <div className="py-2 text-gray-600">
                                                {tab === "Characteristics" && (
                                                    <ul className="list-disc pl-5">
                                                        {product.characteristics.map(
                                                            (item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="mb-1"
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                                {tab === "Care" && (
                                                    <ul className="list-disc pl-5">
                                                        {product.care.map(
                                                            (item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="mb-1"
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                                {tab === "Shipping" && (
                                                    <ul className="list-disc pl-5">
                                                        {product.shipping.map(
                                                            (item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="mb-1"
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                                {tab === "Returns" && (
                                                    <ul className="list-disc pl-5">
                                                        {product.returns.map(
                                                            (item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="mb-1"
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductDetails;