import { React, useEffect, useState } from "react";
import APIHandler from "../../../helpers/axiosApiHelper";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../Redux/reducers/loaderSlicer";
import { triggerSnackbar } from "../../../Redux/reducers/snackbarSlicer";
import Select from 'react-select';
import colorPalette from "../../../constants/colors";

const dimensionUnitOptions = [
    {
        name: "Centimeter",
        abbreviation: "cm"
    },
    {
        name: "Inch",
        abbreviation: "in"
    },
    {
        name: "Foot",
        abbreviation: "ft"
    },
    {
        name: "Meter",
        abbreviation: "m"
    },
]

export default function UpdateProductForm({ initialData, showFormHandler }) {
    const dispatch = useDispatch();
    const [existingData, setExistingData] = useState(initialData);
    const existingDimension = dimensionUnitOptions.filter(item => item['abbreviation'] === existingData.dimension_unit)
    const [productName, setProductName] = useState(existingData.name);
    const [description, setDescription] = useState(existingData.description);
    const [productCode, setProductCode] = useState(existingData.product_code || '');
    const [width, setWidth] = useState(existingData.width);
    const [itemLength, setItemLength] = useState(existingData.length);
    const [productPrice, setProductPrice] = useState(existingData.price);
    // const [file, setFile] = useState(existingData.media.map(item => item.name) || []);
    const [disableButton, setDisableButton] = useState(true);
    const [showWidthLengthValidationError, setShowWidthLengthValidationError] = useState(false);
    const [showProductPrice, setShowProductPrice] = useState(false);
    const [selectedDimension, setSelectedDimension] = useState(existingDimension.length ? existingDimension[0] : null);
    const [categoriesDropdownOptions, setCategoriesDropdownOptions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [productNameList, setProductNameList] = useState([]);
    const [duplicateName, setDuplicateName] = useState(false);

    const getCategoryDropdownOptions = async () => {
        dispatch(showOrHideLoader());
        let apiData = await APIHandler("categories", "listCategoryDropdown", "GET", null, null);
        dispatch(showOrHideLoader());
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                setCategoriesDropdownOptions(apiData.data);
                const existingCategory = apiData.data.filter(item => item['key'] === existingData.category_key);
                setSelectedCategory(existingCategory.length ? existingCategory[0] : null)
            }
        }
    }
    useEffect(() => {
        getCategoryDropdownOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (productName.length && productCode.length && (duplicateName === false)
            // && file.length > 0 
            && selectedCategory !== null && selectedDimension !== null) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [productName
        // , file
        , selectedCategory, selectedDimension, productCode, duplicateName]);

    const handleUpdateSubmitForm = async () => {
        dispatch(showOrHideLoader());
        // const headers = {
        //     "Content-Type": "multipart/form-data",
        // };

        const body = {
            key: existingData.key,
            name: productName,
            product_code: productCode,
            description: description,
            width: parseInt(width),
            length: parseInt(itemLength),
            price: parseInt(productPrice),
            // photo: file,
            dimension_unit: selectedDimension['abbreviation'],
            category_key: selectedCategory['key']
        };

        let apiData = await APIHandler(
            "products",
            `updateProduct`,
            "PUT",
            body,
            null
        );
        dispatch(showOrHideLoader());
        if (apiData != null) {
            dispatch(
                triggerSnackbar({
                    show: true,
                    message: apiData.message,
                    type: apiData.status,
                })
            );
     
            if (apiData.statusCode === 200) {
                showFormHandler(false);
            }
        }
    };

    const getProductsNameList = async () => {
        dispatch(showOrHideLoader())
        let apiData = await APIHandler("products", "getAllProductName", "GET", null, null);
        dispatch(showOrHideLoader())
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                let list = apiData.data;
                setProductNameList(list);
            }
        }
    }

    useEffect(() => {
        getProductsNameList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleName = (event) => {
        let name = event.target.value;
        setProductName(name);
        let nameExist = productNameList.filter(item => item === name);
        if (nameExist.length) {
            setDuplicateName(true);
        }
        else {
            setDuplicateName(false);
        }
    }

    const handleProductCode = (event) => {
        let productCode = event.target.value;
        setProductCode(productCode);
    };
    const handleWidth = (event) => {
        let width = event.target.value;
        setWidth(parseInt(width));
    };
    const handleLength = (event) => {
        let itemLength = event.target.value;
        setItemLength(parseInt(itemLength));
    };
    useEffect(() => {
        if (width > 0 && itemLength > 0) {
            setShowWidthLengthValidationError(false);
        }
        else {
            setShowWidthLengthValidationError(true)
        }
    }, [itemLength, width])

    const handleProductPrice = (event) => {
        let productPrice = event.target.value;
        setProductPrice(productPrice);
        if (productPrice > 0) {
            setShowProductPrice(false);
        }
        else {
            setShowProductPrice(true);
        }
    };
    // const handleFile = (event) => {
    //     let file = event.target.files;
    //     setFile(file);
    // };
    const handleDescription = (event) => {
        let description = event.target.value;
        setDescription(description);
    };

    const categoriesDropdownFormatOptionLabel = ({ key, name }) => (
        <div key={key}>
            <div>{name}</div>
        </div>
    );
    const dimensionsDropdownFormatOptionLabel = ({ abbreviation, name }) => (
        <div style={{ display: "flex" }}>
            <div>{name}</div>
            <div style={{ marginLeft: "10px", color: "#ccc" }}>
                {abbreviation}
            </div>
        </div>
    );

    const dropdownStyles = {
        control: styles => ({ ...styles, backgroundColor: "white", color: "white", borderRadius: "8px" }),
        input: styles => ({ ...styles, color: "white" }),
        singleValue: styles => ({ ...styles, color: "black" }),
    }

    useEffect(() => {
        if (initialData) {
            setExistingData(initialData)
        }
    }, [initialData])
    return (
        <>
            <div
                id="crud-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="z-60 bg-[#000000] bg-opacity-80 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-screen" 
            >
                <div
                    className="relative p-4 w-full max-w-md max-h-full left-1/2 top-1/2"
                    style={{
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <div className="relative bg-[#051641] rounded-lg shadow" style={{ ...colorPalette.carouselForm }} >
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t" >
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-[#8e493b]">
                                Update Product
                            </h3>
                            <button
                                onClick={() => {
                                    showFormHandler(false);
                                }}
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-toggle="crud-modal"
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form
                            className="p-4 md:p-5"
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2">
                                    <label
                                        htmlFor="name"
                                        name="name"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                    >
                                        Name
                                    </label>
                                    <input
                                        onInput={handleName}
                                        type="text"
                                        id="name"
                                        value={productName}
                                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Type category name"
                                        required
                                    />
                                    <p className='text-[red] font-semibold' style={{ display: duplicateName ? "block" : 'none' }}>Category with this name already exist</p>

                                </div>
                            </div>
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2">
                                    <label
                                        htmlFor="name"
                                        name="name"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                    >
                                        Description
                                    </label>
                                    <textarea onInput={handleDescription} value={description} id="description" rows="3" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Write category description here"></textarea>
                                </div>
                            </div>
                            {/* Select Category Start */}
                            <div>
                                <label
                                    htmlFor="name"
                                    name="name"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                >
                                    Category
                                </label>
                                <Select
                                    styles={dropdownStyles}
                                    getOptionValue={(opt) => opt.name}
                                    isSearchable={true}
                                    isClearable={true}
                                    name="categoriesDropdown"
                                    options={categoriesDropdownOptions}
                                    formatOptionLabel={categoriesDropdownFormatOptionLabel}
                                    placeholder={'Select Category'}
                                    value={selectedCategory}
                                    onChange={(event) => {
                                        setSelectedCategory(event)
                                    }}
                                    required
                                />
                            </div>
                            {/* Select Category End */}


                            {/* Dimension Unit End */}
                            <div className="col-span-2">
                                <label
                                    htmlFor="name"
                                    name="name"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                >
                                    Product Code
                                </label>
                                <input
                                    onInput={handleProductCode}
                                    type="text"
                                    value={productCode}
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Type product code"
                                    required
                                />
                            </div>
                            <div className=" col-span-2">
                                <label

                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                >
                                    Dimension Unit
                                </label>
                                <Select
                                    styles={dropdownStyles}
                                    getOptionValue={(opt) => opt.name}
                                    options={dimensionUnitOptions}
                                    formatOptionLabel={dimensionsDropdownFormatOptionLabel}
                                    placeholder={'Select Dimension Unit'}
                                    value={selectedDimension}
                                    onChange={(event) => {
                                        setSelectedDimension(event)
                                    }}
                                    required
                                />
                            </div>
                            <div className=" grid grid-rows-2 grid-flow-col gap-2">
                                <div className="row-end-3 row-span-2 ...">
                                    <label
                                        htmlFor="name"
                                        name="name"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                    >
                                        Width
                                    </label>
                                    <input
                                        onInput={handleWidth}
                                        type="number"
                                        value={width}
                                        min={1}
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Enter width"
                                        required
                                    />
                                </div>
                                <div className="row-start-1 row-end-4 ...">
                                    <label
                                        htmlFor="name"
                                        name="name"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                    >
                                        Length
                                    </label>
                                    <input
                                        onInput={handleLength}
                                        value={itemLength}
                                        min={1}
                                        type="number"
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Enter length"
                                        required
                                    />
                                </div>

                            </div>
                            <p className="text-[red] font-semibold" style={{ display: showWidthLengthValidationError ? 'block' : 'none' }}>Length or Width cannot be 0</p>
                            <div className="col-span-2">
                                <label
                                    htmlFor="name"
                                    name="name"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#8e493b]"
                                >
                                    Product price
                                </label>
                                <input
                                    onInput={handleProductPrice}
                                    type="number"
                                    value={productPrice}
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-800 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Enter product price"
                                    required
                                />
                            </div>
                            <p className="text-[red] font-semibold" style={{ display: showProductPrice ? 'block' : 'none' }}>Product price cannot not be or 0 </p>
                            {/* <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="mt-2 mb-4 flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-800"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">
                                                Click to upload
                                            </span>{" "}
                                            or drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            SVG, PNG, JPG or Webp
                                        </p>
                                    </div>
                                    <input
                                        onInput={handleFile}
                                        on
                                        accept="image/*"
                                        id="dropzone-file"
                                        type="file"
                                        name="file"
                                        multiple
                                        required
                                    />
                                </label>
                            </div> */}

                            <button
                                style={{
                                    cursor: disableButton ? "none" : "pointer",
                                }}
                                disabled={disableButton}
                                onClick={handleUpdateSubmitForm}
                                type="submit"
                                className={`text-white inline-flex items-center bg-[#8e493b]  rounded-lg text-sm px-5 py-2.5 text-center mt-2.5`}
                            >
                                <svg
                                    className="me-1 -ms-1 w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}