import React, { useState } from "react";
import colorPalette from "../constants/colors";

const AboutUs = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <section id="aboutus" className="text-primary py-4">
            <div className="container mx-auto px-4 text-center">
                <div className="mb-8">
                    <hr class={`w-full h-1 mx-auto my-4 border-0 rounded md:my-8`} style={{ backgroundColor: colorPalette.navbar.backgroundColor }} />
                    <h2 className="text-3xl font-semibold">About Us</h2>
                </div>
                <p className="text-lg leading-relaxed max-w-3xl mx-auto mb-8">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Ipsa vitae perferendis, sapiente quos libero reprehenderit
                    porro cupiditate veniam mollitia magnam perspiciatis quaerat
                    nihil, error distinctio itaque corporis neque adipisci iure
                    maiores. Atque, libero temporibus. Ipsa, iure sint vel
                    aspernatur accusantium commodi sunt voluptatum vitae, facere
                    in fuga quo dolor quam. Adipisci minima, consectetur fugiat
                    tenetur molestias dolore hic omnis perferendis? Ut ducimus
                    magnam suscipit possimus facere laboriosam voluptatibus
                    porro, omnis illum maiores, nihil sequi reprehenderit amet
                    dolore commodi! Quae laudantium cupiditate dicta quibusdam
                    modi voluptas ut vitae nisi ullam dignissimos? <br />
                    <br />
                    From its inception to now, Royale Touche has continued to
                    deliver quality in every product it manufactures. Our
                    success as the best laminate brand in India has been
                    directly attributable to our ever-changing designs and
                    never-changing quality.
                </p>
                {isExpanded && (
                    <div className="text-lg leading-relaxed max-w-3xl mx-auto mb-8">
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Explicabo corrupti officia asperiores a sequi
                            doloribus vero debitis ex labore quasi facilis
                            aliquid ea perspiciatis, illo fuga ipsam atque. Enim
                            quo magnam voluptate fuga natus ullam, velit eos
                            sint provident saepe rem officiis aspernatur sit
                            commodi possimus perspiciatis voluptatibus fugit
                            doloribus veniam expedita hic amet. Facere maxime,
                            consectetur accusamus doloremque quos eligendi quas
                            unde magnam earum. Voluptatem illo dignissimos sint
                            doloribus.
                        </p>
                        <p className="mt-4">
                            Over the years, we have expanded our product range
                            to include a variety of finishes and textures,
                            catering to the diverse needs of our customers. Our
                            commitment to excellence has earned us a loyal
                            customer base and numerous accolades in the
                            industry.
                        </p>
                    </div>
                )}
                <button
                    onClick={handleToggle}
                    className="mb-14 text-white font-semibold py-2 px-6 rounded  transition duration-300"
                    style={{ ...colorPalette.navbar }}
                >
                    {isExpanded ? "Read Less" : "Read More"}
                </button>
            </div>
        </section>
    );
};

export default AboutUs;
