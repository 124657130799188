import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import colorPalette from '../constants/colors';

const Footer = () => {
  return (
    <footer className="pt-12 pb-6 text-center" style={{ ...colorPalette.footer }}>
      <div className="container mx-auto px-4">
        {/* Responsive Grid for Footer Sections */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2" style={{ ...colorPalette.footerQuickLinks }}>
              <li >
                <a href="/" className=" hover:text-white transition-colors " >
                  Home
                </a>
              </li>
              {/* <li>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  About Us
                </a>
              </li> */}
              <li>
                <a href="/" className=" hover:text-white transition-colors ">
                  Products
                </a>
              </li>
              {/* <li>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  Services
                </a>
              </li> */}
              <li>
                <a href="/" className=" hover:text-white transition-colors ">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Information Section */}
          <div style={{ ...colorPalette.footerQuickLinks }}>
            <h4 className="text-lg font-semibold mb-4" style={{ color: colorPalette.footer.color }}>Contact Us</h4>
            <p className="text-gray-400" style={{ ...colorPalette.footerQuickLinks }}>1234 Laminates Street<br />City, State, 12345</p>
            <p className="text-gray-400 mt-2" style={{ ...colorPalette.footerQuickLinks }}>Email: info@naturelaminates.com</p>
            <p className="text-gray-400" style={{ ...colorPalette.footerQuickLinks }}>Phone: +1 (555) 123-4567</p>
          </div>

          {/* Social Media Section */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
            <div className="flex justify-center space-x-9">
              <a href="/" className="text-gray-400 hover:text-white transition-colors">
                <FaFacebookF style={{ ...colorPalette.footerQuickLinks }} size={20} />
              </a>

              <a href="/" className="text-gray-400 hover:text-white transition-colors">
                <FaInstagram style={{ ...colorPalette.footerQuickLinks }} size={20} />
              </a>
              <a href="/" className="text-gray-400 hover:text-white transition-colors">
                <FaLinkedinIn style={{ ...colorPalette.footerQuickLinks }} size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="flex mt-8 border-t border-gray-800 pt-4 text-center justify-evenly text-sm flex-wrap gap-2">
          <p className="text-gray-400">
            COPYRIGHT &copy; 2024 ALL RIGHTS RESERVED BY {(process.env.REACT_APP_NAME).toUpperCase()}.
          </p>
          <p className="text-gray-400">
            DESIGNED & DEVELOPED BY <a href="https://samsinfosoft.com/" target="_blank" className="text-bold hover:underline target:blank" rel="noreferrer" style={{ ...colorPalette.footerQuickLinks }}>SAMS INFOSOFT PVT. LTD.</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
