import React, { useState } from 'react';
import colorPalette from '../../constants/colors'; // Assuming you have a custom color palette

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header
      className="py-4 fixed w-full top-0 z-50 shadow-md "
      style={{ ...colorPalette.navbar }}
    >
      <div className="container mx-auto flex justify-between items-center px-4 lg:px-8">
        {/* Logo */}
        <h1 className="text-2xl font-bold text-navy-600 text-center">
          <a href="/" className="hover:text-yellow-500 transition">
            {process.env.REACT_APP_NAME}
          </a>
        </h1>

        {/* Search Bar */}
        {/* <div className="hidden lg:flex mx-9 w-full max-w-md">
          <input
            type="text"
            placeholder="Search..."
            className="px-4 py-2 w-full text-black bg-gray-100 rounded-l-md focus:outline-none focus:ring-2 focus:ring-gold-500"
          />
          <button
            type="button"
            className="bg-gold-500 text-white px-4 py-2 rounded-r-md hover:bg-gold-600 transition focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11 3a8 8 0 10-4.906 14.32l5.226 5.225a1 1 0 001.415-1.415l-5.226-5.225A8 8 0 0011 3zm-7 8a7 7 0 1114 0 7 7 0 01-14 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div> */}

        {/* Hamburger menu button for small screens */}
        <button
          className="lg:hidden text-navy-600 focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
            />
          </svg>
        </button>

        {/* Navigation links */}
        <nav
          className={`lg:flex  lg:space-x-8 text-center  transition duration-300 ease-in-out absolute lg:static lg:bg-transparent  w-full left-0 top-16 lg:top-auto ${isOpen ? 'block' : 'hidden'
            }`} style={{ ...colorPalette.navbar }}
          aria-label="Main Navigation"
        >
          <a
            href="/"
            className="font-semibold  text-lg	block px-4 py-2 lg:inline-block hover:text-yellow-500 transition"
          >
            Home
          </a>
          <a
            href="/categories"
            className="font-semibold  text-lg	block px-4 py-2 lg:inline-block hover:text-yellow-500 transition"
          >
            Categories
          </a>
          <a
            href="/contact"
            className="font-semibold  text-lg	block px-4 py-2 lg:inline-block hover:text-yellow-500 transition"
          >
            Contact
          </a>
          <a
            href="/about"
            className="font-semibold  text-lg	block px-4 py-2 lg:inline-block hover:text-yellow-500 transition"
          >
            About Us
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
