import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import OnlyLogoNavbar from '../Navbars/OnlyLogoNavbar'
import { motion } from 'framer-motion';
// import Error from "../Alerts/Error";
import APIHandler from '../../helpers/axiosApiHelper';
import { showOrHideLoader } from '../../Redux/reducers/loaderSlicer';
import { triggerSnackbar } from '../../Redux/reducers/snackbarSlicer';
import { useDispatch } from 'react-redux';

export default function SignIn() {
    const navigate = useNavigate();
    // const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValidation, setPasswordValidation] = useState(false);

    // const message = "Incorrect username or password"
    const dispatch = useDispatch();
    // const handleOpen = () => {
    //     setOpen(true);
    // };
    const handleEmail = (event) => {
        let email = event.target.value;
        setEmail(email);
    };
    const handlePassword = (event) => {
        let password = event.target.value;
        setPassword(password);
    };
    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleLogIn = async () => {
        dispatch(showOrHideLoader())
        const body = {
            "email": email,
            "password": password,
        }
        let apiData = await APIHandler(
            "user",
            "login",
            "post",
            body,
            null
        );
        dispatch(showOrHideLoader())
        if (apiData != null) {
            if (apiData.statusCode === 200) {
                let { access_token } = apiData.data;
                localStorage.setItem('accessToken', access_token);
                navigate("/admin/dashboard");
            }
            else if (apiData.statusCode === 400) {
                dispatch(triggerSnackbar({ show: true, message: apiData.message, type: apiData.status }));
            }

        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // handleOpen();
        handleLogIn();

    }
    useEffect(() => {
        if (email.length > 0 && password.length > 0) {
            setPasswordValidation(false);
        }
        else {
            setPasswordValidation(true);
        }
    }, [email, password]);
    return (
        <>
            <OnlyLogoNavbar />
            <section id="sign-in" className="py-12 bg-white flex flex-col justify-center items-center">
                <div className="container mx-auto">
                    <h2 className="text-3xl font-semibold text-center mb-8">Verify you are an admin</h2>
                    <motion.form
                        className="max-w-lg mx-auto bg-gray-100 p-8 rounded-lg shadow-md"

                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        onSubmit={handleSubmit}
                    >
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700">Email</label>
                            <input
                                onInput={handleEmail}
                                type="email"
                                id="email"
                                name="email"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                required
                            />
                            {/* <p className="text-[red] font-semibold" style={{ display: emailValidation ? 'block' : 'none' }}>Enter valid email</p> */}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700">Password</label>
                            <input
                                onInput={handlePassword}
                                type='password'
                                id="password"
                                name="password"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                rows="4"

                            ></input>
                            <p className="text-[red] font-semibold" style={{ display: passwordValidation ? 'block' : 'none' }}>Both field are mandatory to fill</p>
                        </div>
                        <button
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition"
                        >
                            Sign In
                        </button>
                    </motion.form>
                    {/* {open ? <Error message={message} /> : null} */}
                </div>
            </section>
        </>
    )
}
