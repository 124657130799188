import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import colorPalette from '../../constants/colors';

const AddButton = ({ label, onClick, style = {} }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="flex justify-start items-center flex-wrap">
            <button
                onClick={onClick}
                type="button"
                className="my-2 text-white font-medium mx-2 rounded-full flex px-3  text-center me-2 my-4 h-12 me-4 
                w-100 sm:w-100 md:w-100 lg:w-100 xl:w-100"
                style={{ ...colorPalette.headerColor, ...(isHovered ? colorPalette.buttonHover : style) }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className="flex gap-1 rounded-full justify-center items-center text-base pt-1 py-auto px-auto pr-2"
                >
                    <span>
                        <Plus />
                    </span>
                    <span className="my-2 text-base">
                        {label}
                    </span>
                </div>
            </button>
        </div>
    );
};

export default AddButton;
