import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import APIHandler from "../../helpers/axiosApiHelper";
import ProductCard from "../Products/ProductCard";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../Redux/reducers/loaderSlicer";

function CategoryDetails() {
    const dispatch = useDispatch();
    const { categoryId } = useParams();
    const [categoryName, setCategoryName] = useState("");
    const [coverImage, setCoverImage] = useState(null);
    const [categoryDescription, setCategoryDescription] = useState("");
    const [products, setProduct] = useState([]);

    const getCategoryDetails = async () => {
        dispatch(showOrHideLoader());
        let apiData = await APIHandler(
            "categories",
            `categoryDetails/${categoryId}`,
            "GET",
            null,
            null
        );
        dispatch(showOrHideLoader());
        if (apiData != null) {
            let { data, statusCode } = apiData;
            if (statusCode === 200) {
                setCoverImage(data.photo);
                setCategoryName(data.name);
                setCategoryDescription(data.description);
            }
        }
    };

    const getProductByCategory = async () => {
        dispatch(showOrHideLoader());
        let apiData = await APIHandler(
            "products",
            `productDetailsByCategoryId/${categoryId}?statuse=active`,
            "GET",
            null,
            null
        );
        dispatch(showOrHideLoader());
        if (apiData != null) {
            let { data, statusCode } = apiData;
            if (statusCode === 200) {
                setProduct(data);
            }
        }
    };

    useEffect(() => {
        getCategoryDetails();
        getProductByCategory();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="flex flex-col">
                {/* Heading Section with Background Image */}
                <section
                    className="relative flex flex-col items-center justify-center bg-cover bg-center mt-16 aspect-[16/10] md:aspect-[16/6]"
                    style={{ backgroundImage: `url(${coverImage})`, width: '100', objectFit: 'cover' }}
                >
                    {/* <div className="absolute inset-0 bg-black bg-opacity-50"></div> */}
                    <div className="relative z-10 text-center text-white px-4 py-4 bg-[black] bg-opacity-50">
                        <h2 className="text-4xl md:text-6xl font-bold mb-4">{categoryName}</h2>
                        <h3 className="text-lg md:text-2xl md:px-36 ">{categoryDescription}</h3>
                    </div>
                </section>

                {/* Product Cards Section */}
                <h1 className="text-center mt-16 font-bold text-2xl md:text-4xl text-primary">Products in this category</h1>
                <section className="py-16 px-8 md:px-16">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-8" >
                        {/* Product Card 1 */}
                        {products.map((item) => {
                            return (
                                <ProductCard item={item} />
                            )
                        })}
                    </div>
                </section>
            </div>
        </>
    );
}

export default CategoryDetails;