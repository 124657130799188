import React from 'react'

const TruncatedText = ({ text, limit }) => {
    if (text != null) {
        const truncatedText =
            text.length > limit ? text.substring(0, limit) + "..." : text;
        return (
            <p className="mb-3 font-normal text-wrap">
                {truncatedText}
            </p>
        );
    } else {
        return (
            <p className="mb-3 font-normal text-wrap">
                No description added
            </p>
        );
    }
};

export default TruncatedText