import React from 'react';
import Sidebar, { SidebarItem } from './Sidebar';
import { ImageIcon, SquareStack, StretchHorizontal } from 'lucide-react';
import Carousel from './Carousel/Carousel';
import Products from './Products/Products';
import Categories from './Category/Categories';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showOrHideLoader } from '../../Redux/reducers/loaderSlicer';
import APIHandler from '../../helpers/axiosApiHelper';
export default function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selected = useSelector((state) => state.sidebarActive.selector);
    useEffect(() => {
        let accessToken = localStorage.getItem('accessToken');
        if (accessToken === undefined || accessToken === null || accessToken === 'null' || accessToken === '') {
            navigate('/admin');
        }
        else {
            tokenValidation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const tokenValidation = async () => {
        dispatch(showOrHideLoader())
        await APIHandler("user", "validateToken", "GET", null, null);
        dispatch(showOrHideLoader())
    };

    return (
        <div className='flex py-16 sm:py-24 flex-wrap sm:flex-nowrap'>
            <Sidebar>
                <SidebarItem text={"Categories"} icon={<SquareStack />} selector={'categories'} selected={selected} />
                <SidebarItem text={"Carousel"} icon={<ImageIcon />} selector={'carousels'} />
                <SidebarItem text={"Products"} icon={<StretchHorizontal />} selector={'products'} />
            </Sidebar>
            {(() => {
                switch (selected) {
                    case "categories":
                        return <Categories />

                    case "carousels":
                        return <Carousel />

                    case "products":
                        return <Products />

                    default:
                        return null;
                }
            })()}
        </div>
    )
}
