import React from 'react';


import EcoFriendly from '../assets/icons/eco-friendly.png';
import WaterResistant from '../assets/icons/water-resistant.png';
import AffordablePricing from '../assets/icons/affordable-pricing.png';
// import colorPalette from './constants/colors';

const FeaturesSection = () => {

  const features = [
    {
      title: 'Eco-Friendly Materials',
      description:
        'Our laminates are made from sustainable materials, ensuring environmental protection and high quality.',
      image: EcoFriendly,
    },


    {
      title: 'Water Resistant',
      description:
        'Ideal for kitchens and bathrooms, our laminates are water-resistant and prevent moisture damage.',
      image: WaterResistant,
    },
    {
      title: 'Affordable Pricing',
      description:
        'Get high-quality laminates at competitive prices, ensuring value for money.',
      image: AffordablePricing,
    },
  ];

  return (
    // <section id="features" className="py-16 bg-orange-200">
    <section id="features" className="py-16 text-primary">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-12">
          Our Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          {features.map((feature, index) => (
            <div
              key={index}
            //   className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow text-center"
            >
              <div className="flex justify-center items-center mb-4">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-16 h-16 object-contain"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                {feature.title}
              </h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
